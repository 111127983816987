// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

import { config, TenantConfigService } from '@app/core';

// The list of file replacements can be found in `angular.json`.
const baseApiUrl = 'https://apitest.env.carzapp.com.au/';

export const environment = {
  name: 'Local',
  version: TenantConfigService.adminVersion(config.tenantCode),
  production: false,

  baseApiUrl: baseApiUrl,

  baseOrganizationsApiUrl: `${baseApiUrl}identity/v1/organizations`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
