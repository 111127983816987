import { Component, Input, OnInit } from '@angular/core';

import { NotificationService } from '@app/data';

import { StateService, config } from '@app/core';

import {
  Notification,
  NotificationChannels,
  NotificationHistorySummary
} from '@app/model';
import { InsightService } from '@app/insight';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {
  @Input() isAll: boolean = false;
  @Input() notifications: NotificationHistorySummary[] = [];

  public constructor(
    public state: StateService,
    public notificationService: NotificationService,
    private insightService: InsightService
  ) {}

  public ngOnInit(): void {}

  // methods
  public getBadgeClass(isRead: boolean): any {
    return { unread: !isRead };
  }

  public async onReadMessage(
    currentNotification: NotificationHistorySummary,
    isRemoveButton: boolean = false
  ): Promise<void> {
    // log insight
    this.insightService.logEvent('NotificationMarkedAsRead', {
      notification: currentNotification
    });

    // update is read
    this.notification.allNotifications = this.notification.allNotifications.map(
      (notification: any) => {
        if (notification.code === currentNotification.code) {
          notification.isRead = true;
        }

        return notification;
      }
    );

    if (!this.isAll) {
      this.notification.unReadNotifications = this.notification.allNotifications.filter(
        (notification: NotificationHistorySummary) => {
          return !notification.isRead;
        }
      );
    }

    this.notificationService.init(this.notification);

    // for details
    if (!isRemoveButton) {
      this.state.setIsShowNotificationBox(!this.state.isShowNotificationBox);

      const url = this.notificationService.getDetailsUrl(currentNotification);
      await this.state.navigateByUrl(url);
    }

    await this.notificationService.markAsRead(
      NotificationChannels.WebNotification,
      currentNotification.code
    );
  }

  public getImageUrl(item: any): string {
    return item.thumbnailUrl ? item.thumbnailUrl : item.url ? item.url : '';
  }

  public getAltText(item: any): string {
    return item.title.charAt(0);
  }

  // getters
  public get notification(): Notification {
    let notification: Notification;

    this.state.on(
      this.state.isNotificationsLoaded$,
      (isNotificationsLoaded: boolean) => {
        if (isNotificationsLoaded) {
          notification = this.notificationService.collection.item;
        }
      }
    );

    return notification;
  }
}
