import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@app/auth';

import { config } from '@app/core';

const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: `${config.tenantCode}/dashboard`
  },
  {
    path: ':tenantCode',
    children: [
      {
        path: '',
        loadChildren: () => import('@app/admin/panel').then(m => m.PanelModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'login',
        loadChildren: () => import('@app/login').then(m => m.LoginModule),
        resolve: [AuthGuardService]
      },
      {
        path: 'error',
        loadChildren: () =>
          import('@app/error-pages').then(m => m.ErrorPagesModule)
      },
      {
        path: '**',
        loadChildren: () =>
          import('@app/error-pages').then(m => m.ErrorPagesModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
